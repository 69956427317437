<template>
  <div v-if="!unMountComponent">
    <banner :list="list"></banner>
    <menu-tab
      :menu="menu"
      :swiper="true"
      :initIndex="index"
      :slidesPerView="2"
      @change="selectMenu"
    ></menu-tab>

    <list url="/M/Server/GetLastTopic" :show="currentMenuItem == '帖子'">
      <template v-slot:default="data">
        <topic-item :topicData="data"></topic-item>
      </template>
    </list>
    <list url="/M/Server/GetDisLastguan" :show="currentMenuItem == '问答'">
      <template v-slot:default="data">
        <topic-item :topicData="data"></topic-item>
      </template>
    </list>

    <!-- <list url="/M/Server/GetDisLastke" :params="{ fenlei: 233}" :show="currentMenuItem == '计量课堂'">
      <template v-slot:default="data">
        <class-item :course="data"></class-item>
      </template>
    </list> 
    
    v-if="currentMenuItem != '计量课堂'"
    -->


    <router-link
      class="edit"
      :to="{
        name: 'PostTopic',
        query: { postType: currentMenuItem == '问答' ? 1 : 0 },
      }"
    > 
      <img src="@/assets/images/mine/icon-write.png" alt="" />
    </router-link>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  nextTick,
  inject,
  watch,
  reactive,
  onActivated,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";

import TopicItem from "@/components/common/TopicItem.vue";
import MenuTab from "@/components/common/MenuTab.vue";

import api from "@/api/axios";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import ClassItem from "@/components/common/ClassItem.vue";

// import usePageTab from "@/hooks/usePageTab";
export default defineComponent({
  name: "Topic",
  components: {
    banner,
    list,
    TopicItem,
    ClassItem,
    MenuTab,
  },
  setup() {
    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M/Discover").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    //  切换列表菜单
    type Menu = "帖子" | "问答" ;
    const menu: Menu[] = reactive(["帖子","问答"]);


    const route = useRoute();
    const index = ref(0);
    const currentMenuItem = ref(menu[index.value]);
    function initData() {
      const type = route.query.type;

      switch (type) {
        case "1":
          index.value = 1;
          break;
        case "2":
          index.value = 2;
          break;
        default:
          index.value = 0;
          break;
      }
      currentMenuItem.value = menu[index.value];
    }
    initData();
    onActivated(() => {
      // initData();
    });

    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    const unMountComponent = ref(false);
    onBeforeRouteLeave((to, from) => {
      if (
        to.name != "TopicDetail" &&
        to.name != "livedetail" &&
        to.name != "Personal"
      ) {
        // unMountComponent.value = true;
        nextTick(() => {
          // unMountComponent.value = false;
        });
      }
      return true;
    });
    return {
      list,
      menu,
      currentMenuItem,
      selectMenu,
      index,
      unMountComponent,
    };
  },
});
</script>
<style lang="scss" scoped>
.edit {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 80%;
  margin-top: -20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 100;
}
.edit img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
